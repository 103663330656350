import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useStore } from "vuex";

export interface OtherUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  address: string;
  avatarUrl: string;
  organization: Organization;
}

export interface Organization {
  _id: string;
  name: string;
  users: OtherUser[];
  admins: OtherUser[];
  projects: Project[];
}

export interface Project {
  _id: string;
  name: string;
  description: string;
  type: string;
  sector: string;
  organization: Organization;
  budget: Budget;
  startDate: string;
  activities: Activity[];
  team: OtherUser[];
  files: BuildingFile[];
  logo: string;
  archived: boolean;
}

export interface Activity {
  _id: string;
  name: string;
  description: string;
  phase: string;
  status: string;
  deadline: string;
  responsible: OtherUser;
  project: Project;
}

export interface BuildingFile {
  _id: string;
  name: string;
  url: string;
  // type: string;
  documentType: string;
  year: string;
  // organization: Organization;
  project: Project;
}

export interface Budget {
  _id: string;
  total: number;
  spent: number;
  notes: string;
}

export interface Data {
  error: unknown; // todo: maybe
  otherUsers: OtherUser[];
  organizations: Organization[];
  projects: Projects;
  // projects: Project[];
  // activities: Activity[];
  // buildingFiles: BuildingFile[];
}

export interface Projects {
  limit: number;
  skip: number;
  total: number;
  result: Project[];
}
export interface BuildingFiles {
  limit: number;
  skip: number;
  total: number;
  result: BuildingFile[];
}

const UNINIT = "uninit";
const UNINIT_MY_PROJECTS = [{ _id: UNINIT }] as Project[];

@Module
export default class DataModule extends VuexModule implements Data {
  error = null;
  otherUsers = [] as OtherUser[];
  organizations = [] as Organization[];
  organization = {} as Organization; // the logged in user's organization
  // projects = [] as Project[];
  // myProjects = [] as Project[];
  // myProjects = [{ _id: "uninit" }] as Project[];
  myProjects = UNINIT_MY_PROJECTS;
  projects = { result: [], skip: 0, total: 10, limit: 10 } as Projects;
  files = { result: [], skip: 0, total: 10, limit: 10 } as BuildingFiles;
  projectTypes = [] as string[];
  sectors = [] as string[];
  loading = [] as boolean[];

  get getOtherUsers(): OtherUser[] {
    // todo: need to fetch list of users at SOME point ...
    return this.otherUsers;
  }

  get getOrganizations(): Organization[] {
    return this.organizations;
  }

  get getOrganization(): Organization {
    return this.organization;
  }

  get getMyProjects(): Project[] {
    if (
      this.myProjects &&
      this.myProjects[0]?._id === UNINIT_MY_PROJECTS[0]._id &&
      !this.loading["myProjects"]
    ) {
      const store = useStore();
      store?.dispatch(Actions.LOAD_MY_PROJECTS, store.getters.currentUser?._id);
      return [];
    }
    return this.myProjects;
  }

  get getProjects(): Projects {
    return this.projects;
  }

  get getFiles(): BuildingFiles {
    return this.files;
  }

  get getProjectTypes(): string[] {
    return this.projectTypes;
  }

  get getSectors(): string[] {
    return this.sectors;
  }

  get getDataError() {
    return this.error;
  }

  @Mutation
  [Mutations.LOADING_ON](prop) {
    this.loading[prop] = true;
  }

  @Mutation
  [Mutations.LOADING_OFF](prop) {
    this.loading[prop] = false;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error;
  }

  @Mutation
  [Mutations.SET_OTHER_USERS](otherUsers) {
    this.otherUsers = otherUsers;
  }

  @Mutation
  [Mutations.SET_ORGANIZATIONS](organizations) {
    this.organizations = organizations;
  }

  @Mutation
  [Mutations.SET_ORGANIZATION](organization) {
    this.organization = organization;
  }

  @Mutation
  [Mutations.SET_MY_PROJECTS](myProjects) {
    this.myProjects = myProjects;
  }

  @Mutation
  [Mutations.UNSET_MY_PROJECTS]() {
    this.myProjects = UNINIT_MY_PROJECTS;
  }

  @Mutation
  [Mutations.SET_PROJECTS](projects) {
    this.projects = projects;
  }
  @Mutation
  [Mutations.SET_FILES](files) {
    this.files = files;
  }

  @Mutation
  [Mutations.SET_PROJECT_TYPES](projectTypes) {
    this.projectTypes = projectTypes;
  }

  @Mutation
  [Mutations.SET_SECTORS](sectors) {
    this.sectors = sectors;
  }

  @Action
  [Actions.LOAD_ORGANIZATIONS]() {
    return ApiService.get("organization", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORGANIZATIONS, data);
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_ORGANIZATION](orgId) {
    if (!orgId) {
      this.context.commit(Mutations.SET_ORGANIZATION, null);
      return;
    }
    return ApiService.get(
      "organization/" + orgId + "?populate=users,admins,waitlist&select=name",
      ""
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORGANIZATION, data);
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_PROJECT_TYPES]() {
    return ApiService.get("projecttype?sort=name", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROJECT_TYPES, data);
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_SECTORS]() {
    return ApiService.get("sector", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SECTORS, data);
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_ALL_PROJECTS]({ skip, limit, query }) {
    const q = query
      ? `&query={ "$or": [{ "name": { "$regex": ".*(${query}).*", "$options": "i" } }, { "description": { "$regex": ".*(${query}).*", "$options": "i" } }] }`
      : "";
    return ApiService.get(
      "project?populate=organization,type,sector" +
        (skip ? `&skip=${skip}` : "") +
        (limit ? `&limit=${limit}` : "") +
        q +
        // (limit ? `&limit=${limit}` : "")
        ""
    )
      .then(({ data }) => {
        console.log("data in load all projects", data);
        this.context.commit(Mutations.SET_PROJECTS, data);
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_ALL_PROJECTS_OF_TYPE]([projectId, projectTypeId]) {
    return ApiService.get(
      `project?query={"type":"${projectTypeId}","_id":{"$ne":"${projectId}"}}&populate=organization,type,sector&select=name,startDate&limit=6&sort=-updatedAt`,
      ""
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_PROJECTS_FOR_ORG](orgId) {
    if (!orgId) return false;
    return ApiService.get("organization/" + orgId + "?populate=projects", "")
      .then(({ data }) => {
        if (data && data.projects) return data.projects;
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_MY_PROJECTS](userId) {
    if (!userId) return;
    this.context.commit(Mutations.LOADING_ON, "myProjects");
    return ApiService.get(
      'project?populate=organization,team&query={"team":"' + userId + '"}'
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MY_PROJECTS, data);
        this.context.commit(Mutations.LOADING_OFF, "myProjects");
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        this.context.commit(Mutations.LOADING_OFF, "myProjects");
      });
  }

  @Action
  [Actions.LOAD_OTHER_USERS]() {
    return ApiService.get("user", "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_OTHER_USERS, data);
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.LOAD_OTHER_USER](userId) {
    return ApiService.get("user/" + userId + "?populate=organization", "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error) return response.data.error;
        return response.data;
      });
  }

  @Action
  [Actions.LOAD_PROJECT](projectId) {
    return ApiService.get(
      `project/${projectId}?populate=team,activities,files,type,sector,organization`
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error) return response.data.error;
        return response.data;
      });
  }

  @Action
  [Actions.CREATE_ORGANIZATION](orgData) {
    return ApiService.post("organization", orgData)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        else this.context.commit(Mutations.SET_ERROR, response.data);
        return false;
      });
  }

  @Action
  [Actions.UPLOAD_FILE](formData) {
    return ApiService.post("upload_file", formData)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        return response.data;
      });
  }

  @Action
  [Actions.UPLOAD_IMAGE](formData) {
    return ApiService.post("upload_image", formData)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        return response.data;
      });
  }

  @Action
  [Actions.UPLOAD_CSV_FILE]([formData, importType]) {
    return ApiService.post(`upload_csv_file?importType=${importType}`, formData)
      .then((data) => {
        return data.data;
      })
      .catch(({ response }) => {
        return response.data;
      });
  }

  @Action
  [Actions.CREATE_PROJECT](projData) {
    return ApiService.post("project", projData)
      .then(() => true)
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_ERROR,
          response.data?.error || response.data
        );
        return response.data;
      });
  }

  @Action
  [Actions.UPDATE_PROJECT](projData) {
    return ApiService.put("project/" + projData._id, projData)
      .then(() => true)
      .catch(({ response }) => response.data || response);
  }

  @Action
  [Actions.CREATE_ACTIVITY](actData) {
    return ApiService.post("activity", actData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.UPDATE_ACTIVITY](actData) {
    return ApiService.put("activity/" + actData._id, actData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.LOAD_ALL_FILES]({ skip, limit, query }) {
    const q = query
      ? `&query={ "$or": [{ "name": { "$regex": ".*(${query}).*", "$options": "i" } }, { "description": { "$regex": ".*(${query}).*", "$options": "i" } }] }`
      : "";
    return ApiService.get(
      'buildingfile?populate=[{"path":"project","populate":{"path":"organization type"}}]' +
        (skip ? `&skip=${skip}` : "") +
        (limit ? `&limit=${limit}` : "") +
        q,
      ""
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FILES, data);
        return data;
      })
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
      });
  }

  @Action
  [Actions.POPULATE_FILES](bulkData) {
    return ApiService.post("buildingfile", bulkData)
      .then(() => true)
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        else this.context.commit(Mutations.SET_ERROR, response.data);
        return false;
      });
  }

  @Action
  [Actions.STORE_FILE_DATA](fileData) {
    return ApiService.post("buildingfile", fileData)
      .then(() => true)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.GET_ORPHAN_USERS]() {
    return ApiService.get('user?query={"organization":null}')
      .then((data) => data.data)
      .catch(({ response }) => {
        if (response.data.error)
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        else this.context.commit(Mutations.SET_ERROR, response.data);
        return false;
      });
  }

  @Action
  [Actions.ADD_ADMIN](pl) {
    return ApiService.post(
      "organization/" + pl.orgId + "?addAdminId=" + pl.addAdminId,
      {}
    )
      .then((data) => data.data)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.REMOVE_ADMIN](pl) {
    return ApiService.post(
      "organization/" + pl.orgId + "?removeAdminId=" + pl.removeAdminId,
      {}
    )
      .then((data) => data.data)
      .catch(({ response }) => response.data);
  }

  @Action
  [Actions.ADD_TEAM_MEMBER](payload) {
    return ApiService.post("project/" + payload.projectId, payload)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.ADD_ORG_MEMBER](payload) {
    return ApiService.post("add_org_member", payload)
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.REMOVE_ORG_MEMBER](userId) {
    return ApiService.post(`remove_org_member?userId=${userId}`, {})
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }
}
