enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  CREATE_ORGANIZATION = "createOrganization",
  CREATE_PROJECT = "createProject",
  LOAD_ORGANIZATIONS = "loadOrganizations",
  LOAD_ORGANIZATION = "loadOrganization",
  LOAD_ALL_PROJECTS = "loadAllProjects",
  LOAD_ALL_PROJECTS_OF_TYPE = "loadAllProjectsOfType",
  LOAD_PROJECTS_FOR_ORG = "loadProjectsForOrg",
  LOAD_MY_PROJECTS = "loadMyProjects",
  LOAD_PROJECT = "loadProject",
  UPDATE_PROJECT = "updateProject",
  LOAD_OTHER_USERS = "loadOtherUsers",
  LOAD_OTHER_USER = "loadOtherUser",
  CHANGE_PASSWORD = "changePassword",
  UPLOAD_FILE = "uploadFile",
  UPLOAD_IMAGE = "uploadImage",
  STORE_FILE_DATA = "storeFileData",
  UPLOAD_CSV_FILE = "uploadCsvFile",
  CREATE_ACTIVITY = "createActivity",
  UPDATE_ACTIVITY = "updateActivity",
  LOAD_PROJECT_TYPES = "loadProjectTypes",
  LOAD_SECTORS = "loadSectors",
  LOAD_ALL_FILES = "loadAllFiles",
  POPULATE_FILES = "populateFiles",
  GET_ORPHAN_USERS = "getOrphanUsers",
  ADD_ORG_MEMBER = "addOrgMember",
  REMOVE_ORG_MEMBER = "removeOrgMember",
  ADD_ADMIN = "addAdmin",
  REMOVE_ADMIN = "removeAdmin",
  ADD_TEAM_MEMBER = "addTeamMember",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  UPDATE_SET_USER = "updateSetUser",
  // SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_OTHER_USERS = "setOtherUsers",
  SET_ORGANIZATIONS = "setOrganizations",
  SET_ORGANIZATION = "setOrganization",
  SET_MY_PROJECTS = "setMyProjects",
  UNSET_MY_PROJECTS = "unsetMyProjects",
  SET_PROJECTS = "setProjects",
  SET_FILES = "setFiles",
  SET_PROJECT_TYPES = "setProjectTypes",
  SET_SECTORS = "setSectors",
  LOADING_ON = "loadingOn",
  LOADING_OFF = "loadingOff",
}

export { Actions, Mutations };
